<template>
  <v-layout class="home pa-2 ma-2" column>
    <v-toolbar flat width="800">
        <v-toolbar-title class="text-h4">Configuration</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
       <v-spacer></v-spacer>
       <v-btn color="primary" @click="saveConfigs">Simpan</v-btn>
    </v-toolbar>
    <v-card class="ma-1 my-2 pa-2" elevation="1" width="800">
      <v-card-text class="pa-2">
        <v-row>
          <v-col cols="3">
            Voucher Emails
          </v-col>
          <v-col>
            <v-textarea dense outlined v-model="configs.voucher_emails" rows="3" hide-details="auto"></v-textarea>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Minimum Android Version
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.current_android_version" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Latest Cancel Order (H-)
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.latest_cancel_order" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Paralayang Admin Fee (%)
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.paralayang_admin_fee" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Nomor Contact Support
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.support_contact" hide-details="auto"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Batas Waktu Pembayaran
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.time_limit_payment" hide-details="auto" suffix="menit"></v-text-field>
          </v-col>
        </v-row>

        <v-row>
          <v-col cols="3">
            Batas Waktu Pembayaran Glamping Adventuraja
          </v-col>
          <v-col>
            <v-text-field dense outlined v-model="configs.time_limit_direct_payment" hide-details="auto" suffix="menit"></v-text-field>
          </v-col>
        </v-row>

      </v-card-text>
    </v-card>

    <v-overlay :value="onProgress">
        <v-progress-circular :size="50" color="amber" indeterminate></v-progress-circular>
    </v-overlay>  

  </v-layout>
</template>

<script>

export default {
  name: 'Home',
  data: () => ({
    onProgress: false,
    configs: {}
  }),
  components: {
  },
  created() {
    this.onProgress = true
    this.$store.dispatch('configs/getObj').then(response => {
      this.onProgress = false
      this.configs = response
      console.log(response)
    }, err => {
      this.onProgress = false
      console.log(err)
    })
  },
  methods: {
    saveConfigs() {
      this.onProgress = true
      this.$store.dispatch('configs/postObj', this.configs).then(response => {
        console.log(response)
        this.onProgress = false
      }, (err)=> {
        this.onProgress = false
        console.log(err)
      })
    }
  },
  computed: {
  }

}
</script>
